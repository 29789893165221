@tailwind base;
@tailwind components;
@tailwind utilities;
@import '/src/theme.scss';

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

.spacer {
    flex: 1 1 auto;
  }

  .border-top {
    border-top: 1px solid var(--border-color);
  }
  .border-bottom {
    border-bottom: 1px solid var(--border-color);
  }

  .mat-toolbar {
    z-index: 100;
  }