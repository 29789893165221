@use '@angular/material' as mat;

@include mat.core();

// Define a dark theme
$dark-theme: mat.m2-define-dark-theme((
 color: (
   primary: mat.m2-define-palette(mat.$m2-blue-palette),
   accent: mat.m2-define-palette(mat.$m2-blue-palette),
 )
));

// Define a light theme
$light-theme: mat.m2-define-light-theme((
 color: (
   primary: mat.m2-define-palette(mat.$m2-light-blue-palette),
   accent: mat.m2-define-palette(mat.$m2-light-blue-palette),
 ),
));


@include mat.all-component-themes($light-theme);
@include mat.strong-focus-indicators-theme($light-theme);


// variables not controlled by angular {
:root {
    --background-color: #ffff;
    --border-color: #DFE0E5;
    --text-color: #172B4D;
    --active-color:#2196f3;
    --tint-color:#2196f3;
    --inactive-color: #757575;
}

html[theme='dark'] {
  @include mat.all-component-colors($dark-theme);
  --background-color: #1D2125;
  --border-color: #363A41;
  --text-color: #ffff;
  --active-color:#2196f3;
  --tint-color:#2196f3;
  --inactive-color: #757575;
}

html, body {
  background-color: var(--background-color);
}

p, h1, h2, h3, h4, h5, span, button {
  color: var(--text-color);
}

.mdc-list-item__primary-text {
  color:var(--text-color) !important;
}

.mdc-icon-button {
  color:var(--tint-color) !important;
}



